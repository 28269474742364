import { Link } from 'gatsby'
import * as React from 'react'

import Button from 'react-bootstrap/Button'

const ShopMeati = (props) => {
  const variant = 'outline-light'
  const btnClass = 'btn-lg'
  const btnText = 'Shop Meati™'
  const type = 'cta button'
  const location = 'null'

  return (
    <Link to="/shop" data-ad-element-type={props.type || type} data-ad-text={props.btnText || btnText} data-ad-button-location={props.location || location} data-ad-button-id="version 1" className="ad-el-tracked">
      <Button
        variant={props.variant || variant}
        className={props.btnClass || btnClass}
      >
        { props.btnText || btnText }
      </Button>
    </Link>
  )
}

export default ShopMeati
